import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import { navigate } from "gatsby";
import NewsletterRow from "../newsletter/NewsletterRow";
import parse from "html-react-parser";
import PageTitle from "../core/PageTitle";
import React, { useRef } from "react";
import Typography from "@material-ui/core/Typography";
import QnA from "../../models/QnA";
import { RouteComponentProps } from "@reach/router";
import Layout from "../core/Layout";
import FeaturedJobsSection from "../jobs/FeaturedJobsSection";
import MakerOSSection from "../ads/MakerOSSection";

interface FAQPageComponentProps extends RouteComponentProps {
  pageContext: {
    qnaArray: [any];
    lastUpdateDate: string;
    currentQnA?: QnA;
    featuredJobs: any[];
  };
}

function parsedQuestion(question: string | null | undefined): string {
  const questionPattern = /^(.+?)(?:\s*<.*)?$/;
  if (question && question !== null) {
    const match = question.match(questionPattern);
    const parsedQuestion = match[1];
    // Get rid of the question mark and replace spaces with "-"
    return parsedQuestion;
  }
  return "";
}

function parsedQuestionWithUnderscores(
  question: string | null | undefined
): string {
  if (question && question !== null) {
    const normalizedQuestion = parsedQuestion(question)
      .replace(/\?/g, "")
      .replace(/ /g, "-");
    return normalizedQuestion;
  }
  return "";
}

export default function FAQPageComponent(props: FAQPageComponentProps) {
  const classes = useStyles();
  const executeScroll = () => ref.current.scrollIntoView();

  const title =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.question.length !== 0
      ? parsedQuestion(props.pageContext.currentQnA?.question)
      : "Frequently Asked Questions about Jetpack Compose | JetpackCompose.app by Vinay Gaba";
  const description =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.answer.length !== 0
      ? props.pageContext.currentQnA?.answer
      : "Find answers to frequently asked questions about Jetpack Compose! Jetpack Compose is the best way to build for Android.";
  const pageSlug =
    props.pageContext.currentQnA !== null &&
    props.pageContext.currentQnA?.question.length !== 0
      ? `/${parsedQuestionWithUnderscores(
          props.pageContext.currentQnA?.question
        )}`
      : "/faq";

  // https://developers.google.com/search/docs/advanced/structured-data/faqpage
  const structuredDataMainEntity = props.pageContext.qnaArray.map((faq) => {
    return {
      "@type": "Question",
      name: parsedQuestion(faq.question),
      acceptedAnswer: {
        "@type": "Answer",
        text: faq.answer,
      },
    };
  });
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: structuredDataMainEntity,
  };

  return (
    <>
      <Layout
        maxWidth="md"
        pageTitle={title}
        pageDescription={description}
        pageSlug={pageSlug}
        pageImageUrl="/faq_poster.png"
        seoImageUrl="/faq_poster.png"
        structuredData={structuredData}
      >
        <PageTitle
          header="Frequently Asked Questions"
          subheader="Find answers to frequently asked questions about Jetpack Compose!"
        />
        <div className={classes.faqContainer}>
          {props.pageContext.qnaArray.map((qna) => {
            const parseQuestionUrl: string = parsedQuestionWithUnderscores(
              qna.question
            );
            const parseQuestion: string = parsedQuestion(qna.question);
            const currentQuestion = parsedQuestion(
              props.pageContext.currentQnA?.question
            );
            return (
              <Accordion
                variant="outlined"
                expanded={parseQuestion === currentQuestion}
                onChange={(event, expanded) => {
                  if (expanded) {
                    navigate("/" + parseQuestionUrl);
                  } else {
                    navigate("/faq");
                  }
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.question}>
                    {parse(qna.question)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className={classes.answer}>
                    {parse(qna.answer)}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <div className={classes.jobs}>
          <MakerOSSection />
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  faqContainer: {
    padding: 16,
  },
  question: {
    fontSize: 20,
    fontFamily: "Nunito Sans",
    color: "#222",
    "& span": {
      fontSize: 16,
    },
    "& a": {
      color: "#4636f7",
    },
  },
  answer: {
    fontSize: 18,
    fontFamily: "Nunito Sans",
    color: "#374151 !important",
    "& a": {
      color: "#222222",
      background: "lightgoldenrodyellow",
      padding: "5px",
      textDecoration: "none",
    },
    "& p": {
      fontSize: 18,
      fontFamily: "Nunito Sans",
      color: "#374151 !important",
    },
  },
  newsletter: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
    marginTop: 64,
  },
  jobs: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    marginTop: 64,
    padding: 16,
  },
});
